
import { defineComponent } from 'vue'
const __default__ = defineComponent({
  name: 'CmsLoader',
  props: {
    color: {
      type: String,
      default: '#000'
    }
  },
  data () {
    return {
      itsTime: false
    }
  },
  mounted () {
    setTimeout(() => { this.itsTime = true }, 2000)
  }
})

import { useCssVars as _useCssVars } from 'vue'
const __injectCSSVars__ = () => {
_useCssVars(_ctx => ({
  "af7e8726": (_ctx.color)
}))}
const __setup__ = __default__.setup
__default__.setup = __setup__
  ? (props, ctx) => { __injectCSSVars__();return __setup__(props, ctx) }
  : __injectCSSVars__

export default __default__