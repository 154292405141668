<template>
  <router-link
    :to="path"
    :class="['logo_container', 'flex_center', {logo_mobile: isMobile}]"
  >
    <transition
      name="logo-rotate"
      appear
    >
      <img
        src="@/assets/sever-logo.svg"
        class="logo_image"
      >
    </transition>
    <h3>СЕВЕР ИНЖИНИРИНГ</h3>
  </router-link>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
export default defineComponent({
  name: 'MainLogo',
  props: {
    path: {
      type: String,
      default: '/'
    },
    isMobile: {
      type: Boolean,
      default: false
    }
  }
})
</script>

<style scoped>
.logo_container {
  display: flex;
  cursor: pointer;
  align-items: center;
  width: fit-content;
  justify-self: center;
}

.logo_container h3 {
  white-space: nowrap;
  padding-left: 1rem;
  /* font-size: 1rem; */
}

.logo_image {
  width: 3rem;
  height: 3rem;
}

.logo_mobile {
  font-size: .8rem;
  /* margin-bottom: 2rem; */
}

.logo_mobile img {
  width: 2.3rem;
  height: 2.3rem;
}

</style>
