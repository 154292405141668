<template>
  <!-- <keep-alive> -->
  <component :is="layout">
    <router-view/>
  </component>
  <!-- </keep-alive> -->
</template>

<script lang="ts">
import MainLayout from './layouts/MainLayout.vue'
import CmsLayout from './layouts/CmsLayout.vue'
import MainMixin from '@/components/MainMixin.vue'
import { useRoute } from 'vue-router' // Чтобы работать с route в Vue3
import { computed } from 'vue'
export default {
  components: {
    MainLayout, CmsLayout
  },
  mixins: [MainMixin],
  data () {
    return {
      isFormActive: false,
      isBurgerMenu: false,
    }
  },
  setup() {
    const route = useRoute()
    return {
      layout: computed(() => route.meta?.layout ?? 'main-layout')
    }
  }
  // computed: {
  //   layout () : string {
  //     const route = useRoute()
  //     console.log(route?.meta?.layout)
  //     return route?.meta?.layout as string
  //     // Вернем имя шаблона из роута или дефолтное значение (MainLayout.vue)
  //     // return route.meta?.layout ?? 'main-layout'
  //   }
  // }
}
</script>

<style>
@import 'https://fonts.googleapis.com/css2?family=Roboto:wght@100;300;500;700;900&display=swap';

*,
*:before,
*:after {
  padding: 0;
  margin: 0;
  border: 0;
  box-sizing: border-box;
}

:root {
  --background_color: #f9f9f9;
  --header_height: 6rem;
  --footer_height: 3em;
  --max_width: 2300px;
  --font_color: #2c3e50;
  --blue: #5FB0CC;
  --max-width: 840px;
}

body {
  font-family: 'Roboto', sans-serif;
  font-display: optional;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-weight: normal;
  color: var(--font_color);
  background-color: var(--background_color);

  text-decoration: none;
  margin: 0;
  padding: 0;
  border: none;

  overflow-y: scroll;
  /* overflow-x: hidden; */
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  /* убирает моргание при нажатии на ссылку в mobile */

  /* -webkit-hyphens: auto;
    -moz-hyphens: auto;
    -ms-hyphens: auto;
    hyphens: auto;
    word-wrap: break-word; */
}

a {
  font-weight: 300;
  color: var(--font_color);
  text-decoration: none;
}

button {
  font-family: 'Roboto', sans-serif;
  background: none;
  font-size: .9rem;
  font-weight: 300;
  white-space: pre;
}

textarea {
  font-family: 'Roboto', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-weight: 300;
  color: var(--font_color);
  outline: none;
}

._hidden {
  -webkit-filter: blur(.5px);
  filter: blur(.5px);
  opacity: .3;
}

.main_wrapper {
  display: flex;
  justify-content: center;
  width: 100%;
  height: 100vh;
}

.main_container {
  width: var(--max_width);
  max-width: var(--max_width);
  height: 100%;
  /* min-height: 100vh; */
  background-color: var(--background_color);
  display: grid;
  grid-template-rows: auto 1fr auto;
}

/*
============================================================
Animation
============================================================
*/

.loc_menu-enter-active {
  transition: all 0.5s cubic-bezier(0.075, 0.82, 0.165, 1);
}

.loc_menu-leave-active {
  transition: all 0.2s cubic-bezier(0.075, 0.82, 0.165, 1);
}

.loc_menu-enter-from,
.loc_menu-leave-to {
  transform: translateY(-.5rem) scaleY(.9);
  opacity: 0;
}

.burger_menu-enter-active {
  transition: all 0.5s cubic-bezier(0.075, 0.82, 0.165, 1);
}
.burger_menu-leave-active {
  transition: all 0.4s cubic-bezier(0.075, 0.82, 0.165, 1);
}
.burger_menu-enter-from,
.burger_menu-leave-to {
  transform: translateX(-100%) scaleX(.9);
  /* opacity: 0; */
}

.filters_menu-enter-active {
  transition: all 0.5s cubic-bezier(0.075, 0.82, 0.165, 1);
}
.filters_menu-leave-active {
  transition: all 0.4s cubic-bezier(0.075, 0.82, 0.165, 1);
}
.filters_menu-enter-from,
.filters_menu-leave-to {
  transform: translateX(100%) scaleX(.9);
  /* opacity: 0; */
}

.fade-enter-active {
  transition: all 1.2s cubic-bezier(0.075, 0.82, 0.165, 1);
}

.fade-leave-active {
  transition: all .7s cubic-bezier(0.075, 0.82, 0.165, 1);
}

.fade-move {
  transition: transform 0.2s ease;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}

.up-down-enter-active {
  transition: all .4s cubic-bezier(0.075, 0.82, 0.165, 1);
}

.up-down-leave-active {
  transition: all .2s;
}

.up-down-enter-from,
.up-down-leave-to {
  transform-origin: center 0;
  transform: translateY(-1rem) scaleY(.95);
  opacity: 0;
}

.left-right-enter-active {
  transition: all .5s cubic-bezier(0.075, 0.82, 0.165, 1);
}

.left-right-leave-active {
  transition: all .4s;
}

.left-right-enter-from,
.left-right-leave-to {
  transform-origin: center 0;
  transform: translateX(-3rem);
  opacity: 0;
}

.right-left-enter-active {
  transition: all .5s cubic-bezier(0.075, 0.82, 0.165, 1);
}

.right-left-leave-active {
  transition: all .4s;
}

.right-left-enter-from,
.right-left-leave-to {
  transform-origin: center 0;
  transform: translateX(3rem);
  opacity: 0;
}

.top-bottom-enter-active {
  transition: all 1s cubic-bezier(0.075, 0.82, 0.165, 1);
}

.top-bottom-leave-active {
  transition: all .4s;
}

.top-bottom-enter-from,
.top-bottom-leave-to {
  transform-origin: center 0;
  transform: translateY(-1rem);
  opacity: 0;
}

.bottom-top-enter-active {
  transition: all 1s cubic-bezier(0.075, 0.82, 0.165, 1);
}

.bottom-top-leave-active {
  transition: all .4s;
}

.bottom-top-enter-from,
.bottom-top-leave-to {
  transform: translateY(1rem);
  opacity: 0;
}

.logo-rotate-enter-active {
  transition: all 2s cubic-bezier(0.075, 0.82, 0.165, 1);
}

.logo-rotate-leave-active {
  transition: all 2s cubic-bezier(0.075, 0.82, 0.165, 1);
}

.logo-rotate-enter-from,
.logo-rotate-leave-to {
  transform: rotate(-180deg);
}

.list-complete-move {
  transition: all 0.5s ease;
  opacity: 1;
}

.list-complete-item {
  transition: all 0.5s ease;
  opacity: 1;
}

.list-complete-enter-from,
.list-complete-leave-to {
  transition: all 0.5s ease;
  position: absolute;
  opacity: 0;
}

.list-complete-leave-active {
  position: absolute;
}

.pop-enter-active {
  transition: all .5s cubic-bezier(0.075, 0.82, 0.165, 1);
}

.pop-leave-active {
  transition: all .5s cubic-bezier(0.075, 0.82, 0.165, 1);
}

.pop-enter-from,
.pop-leave-to {
  animation: pop .5s;
}

@keyframes pop {
  0% {
    transform: scale(1);
  }

  20% {
    transform: scale(0.9);
  }

  100% {
    transform: scale(1);
  }
}

.pump-enter-active {
  animation: pump_in .4s;
}

.pump-enter-from {
  animation: pump_in .4s;
}

.pump-leave-to {
  animation: pump_out .4s;
}

@keyframes pump_in {
  0% {
    transform: scale(0);
  }

  30% {
    transform: scale(1.3);
  }

  100% {
    transform: scale(1);
  }
}

@keyframes pump_out {
  0% {
    transform: scale(1);
  }

  30% {
    transform: scale(1.3);
  }

  100% {
    transform: scale(0);
  }
}

</style>
