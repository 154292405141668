<template>
  <transition name="bottom-top">
    <div
      v-if="showButton"
      class="scroll_button_container"
      @click="scrollUp"
    >
      <!-- <span class="scroll_button_content">Наверх</span>
      <span class="scroll_arrow"/>
      <span class="scroll_arrow"/>
      <span class="scroll_arrow"/> -->
      <div class="back" />
      <img
        src="/img/icon_up.svg"
        alt=""
      >
      <!-- <span class="scroll_button_content">Наверх</span> -->
    </div>
  </transition>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
export default defineComponent({
  name: 'ScrollUp',
  data () {
    return {
      showButton: false
    }
  },
  created () {
    window.addEventListener('scroll', this.userScroll)
  },
  beforeUnmount () {
    window.removeEventListener('scroll', this.userScroll)
  },
  methods: {
    userScroll () {
      // Показывать кнопку прокрутки вверх если скролл страницы стал больше 1000px
      if (window.scrollY > 1000) {
        this.showButton = true
      } else {
        this.showButton = false
      }
    },
    scrollUp () {
      scrollTo(0, 0)
    }
  }
})
</script>

<style scoped>

.scroll_button_container {
  cursor: pointer;
  position: fixed;
  display: grid;
  overflow: hidden;
  column-gap: 1rem;
  align-content: center;
  justify-content: center;
  bottom: -3.5rem;
  left: 50%;
  width: 7rem;
  height: 7rem;
  padding: 1rem;
  background: linear-gradient(to bottom, #4dc6d94d 0%, #5fb1cc2d 100%);
  border-radius: 8rem;
  transform: translateX(-50%);
  transition: 1s cubic-bezier(0.075, 0.82, 0.165, 1);
}

.scroll_button_container:hover {
  /* background: linear-gradient(to bottom, #4dc7d9 0%, #5FB0CC 100%); */
  outline: none;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
  height: 8rem;
  transform: translateX(-50%);
  transition: 1s cubic-bezier(0.075, 0.82, 0.165, 1);
}

.scroll_button_container:active {
  transform: translate(-50%,1rem);
  transition: .5s cubic-bezier(0.075, 0.82, 0.165, 1);
}

.back {
  position: absolute;
  width: 10rem;
  height: 10rem;
  border-radius: 10rem;
  background: linear-gradient(to bottom, #4dc6d9a2 0%, #5FB0CC 100%);
  transform: translate(-5rem,10rem);
  transition: 1s cubic-bezier(0.075, 0.82, 0.165, 1);
}

.scroll_button_container:hover .back{
  transform: translate(-1rem ,-1rem );
  transition: 1s cubic-bezier(0.075, 0.82, 0.165, 1);
}

.scroll_button_container img {
  z-index: 1000;
  justify-self: center;
  height: 3rem;
  transform: translateY(-1rem);
  transition: 1s cubic-bezier(0.075, 0.82, 0.165, 1);
}

.scroll_button_content {
  z-index: 1000;
  display: block;
  font-weight: 300;
  font-size: .9rem;
  transform: scaleY(0);
  transition: 1s cubic-bezier(0.075, 0.82, 0.165, 1);
}

.scroll_button_container:hover .scroll_button_content{
  transform: scaleY(1);
  transition: 1s cubic-bezier(0.075, 0.82, 0.165, 1);
}

</style>
