<template v-if="loaded">
    <div
      v-for="menuItem in topMenuItems"
      :key="menuItem.id"
      :class="[{_hidden: !menuItem.visible}]"
    >
      <router-link
        v-if="menuItem.items.length === 0"
        :to="'/cms' + menuItem.path"
      >
        <div :class="['cms_menu_item', {_hidden: !menuItem.visible}]">
          {{ menuItem.text }}
        </div>
      </router-link>
      <div v-else>
        <router-link :to="'/cms' + menuItem.path">
          <div class="cms_menu_item">
            {{ menuItem.text }}
          </div>
        </router-link>
        <div class="cms_submenu">
          <div
            v-for="submenuItem in menuItem.items"
            :key="submenuItem.id"
            :class="['cms_submenu_item', {_hidden: !submenuItem.visible}]"
          >
            <router-link :to="'/cms/product/' + submenuItem.id">
              {{ submenuItem.text }}
            </router-link>
            <div class="cms_submenu_submenu">
              <div
                v-for="submenuSubmenuItem in submenuItem.items"
                :key="submenuSubmenuItem.id"
                :class="['cms_submenu_submenu_item', {_hidden: !submenuSubmenuItem.visible}]"
              >
                <router-link :to="'/cms/product/' + submenuItem.id + '/' + submenuSubmenuItem.id">
                  {{ submenuSubmenuItem.text }}
                </router-link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <router-link to="/cms/footer">
      <div class="cms_menu_item">
        Текст внизу страницы
      </div>
    </router-link>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import MainMenuMixin from '@/components/MainMenuMixin.vue'
export default defineComponent({
  name: 'CmsLeftMenu',
  mixins: [MainMenuMixin],
  mounted () {
    this.loadJSON(() => {
      this.topMenuItems = this.content
    })
  }
})
</script>

<style scoped>

  .cms_menu_item {
    font-weight: 600;
    padding: .5rem 0;
  }

  .cms_submenu {
    margin: 1rem 0 0 1rem;
  }

  .cms_submenu_item {
    padding: .1rem 0;
    font-size: .9rem;
  }
  .cms_submenu_item a {
    font-weight: 500;
  }

  .cms_submenu_submenu {
    padding: 0.5rem  0 1rem 1rem;
  }

  .cms_submenu_submenu_item {
    padding: .2rem 0;
    font-size: .9rem;
  }

  .cms_submenu_submenu_item a {
    font-weight: 300;
  }

</style>
