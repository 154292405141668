import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, createElementBlock as _createElementBlock, Transition as _Transition, withCtx as _withCtx, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, Fragment as _Fragment, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = (n: any) => (_pushScopeId("data-v-ce5208e8"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 0,
  class: "cms_left_menu"
}
const _hoisted_2 = { key: 0 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_cms_confirm = _resolveComponent("cms-confirm")!
  const _component_main_logo = _resolveComponent("main-logo")!
  const _component_cms_left_menu = _resolveComponent("cms-left-menu")!
  const _component_router_view = _resolveComponent("router-view")!
  const _component_cms_right_menu = _resolveComponent("cms-right-menu")!
  const _component_cms_left_menu_burger = _resolveComponent("cms-left-menu-burger")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    (_ctx.isChanged)
      ? (_openBlock(), _createBlock(_component_cms_confirm, {
          key: 0,
          "is-mobile": _ctx.showMobile,
          onCmsConfirmCanceled: _ctx.setCanceled,
          onCmsConfirmOk: _ctx.setOk
        }, null, 8, ["is-mobile", "onCmsConfirmCanceled", "onCmsConfirmOk"]))
      : _createCommentVNode("", true),
    _createElementVNode("div", {
      class: _normalizeClass(['cms_wrapper', {cms_wrapper_mobile: _ctx.showMobile}, {_overflow_hidden: _ctx.isBurgerMenuActive}])
    }, [
      (!_ctx.showMobile)
        ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
            _createVNode(_Transition, { name: "fade" }, {
              default: _withCtx(() => [
                (!_ctx.isChanged)
                  ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
                      _createVNode(_component_main_logo, {
                        path: "/cms",
                        "is-mobile": true
                      }),
                      (_openBlock(), _createBlock(_component_cms_left_menu, {
                        key: String(_ctx.leftMenuUpdate)
                      }))
                    ]))
                  : _createCommentVNode("", true)
              ]),
              _: 1
            })
          ]))
        : _createCommentVNode("", true),
      _createElementVNode("div", {
        class: _normalizeClass(['cms_content', {cms_content_mobile: _ctx.showMobile}])
      }, [
        (_openBlock(), _createBlock(_component_router_view, {
          key: _ctx.$route.fullPath,
          "is-canceled": _ctx.isCanceled,
          "is-ok": _ctx.isOk,
          onCmsInputChanged: _ctx.setChanged,
          onDataSaved: _cache[0] || (_cache[0] = ($event: any) => (_ctx.leftMenuUpdate = !_ctx.leftMenuUpdate))
        }, null, 8, ["is-canceled", "is-ok", "onCmsInputChanged"]))
      ], 2)
    ], 2),
    (!_ctx.isChanged)
      ? (_openBlock(), _createBlock(_component_cms_right_menu, {
          key: 1,
          user: _ctx.state.userId,
          "is-mobile": _ctx.showMobile
        }, null, 8, ["user", "is-mobile"]))
      : _createCommentVNode("", true),
    (_ctx.showMobile)
      ? (_openBlock(), _createElementBlock(_Fragment, { key: 2 }, [
          (!_ctx.isChanged)
            ? (_openBlock(), _createBlock(_component_cms_left_menu_burger, {
                key: String(_ctx.leftMenuUpdate),
                onShowBurgerMenu: _cache[1] || (_cache[1] = ($event: any) => (_ctx.isBurgerMenuActive = true)),
                onHideBurgerMenu: _cache[2] || (_cache[2] = ($event: any) => (_ctx.isBurgerMenuActive = false)),
                onHideMobile: _cache[3] || (_cache[3] = ($event: any) => (_ctx.isBurgerMenuActive = false))
              }))
            : _createCommentVNode("", true),
          _createElementVNode("div", {
            class: _normalizeClass(['cms_header',{blocked: _ctx.isChanged}])
          }, [
            _createVNode(_component_main_logo, { path: "/cms" })
          ], 2)
        ], 64))
      : _createCommentVNode("", true)
  ], 64))
}