<template>
  <div
    v-if="(!showMobile) && loadedLoc"
    class="location_container"
  >
    <a :href="selectedLocation[2]">
      <h4>{{ selectedLocation[1] }}</h4>
    </a>
    <button
      class="location_menu"
      @touchstart="showLocationMenu = !showLocationMenu"
      @keyup.enter="showLocationMenu = !showLocationMenu"
      @mouseover="showLocationMenu = true"
      @mouseleave="showLocationMenu = false"
    >
      <div>
        <p>
          <span class="blue_dot" />{{ selectedLocation[0] }}
        </p>
      </div>
      <template v-if="contentLoc.length > 1">
        <transition name="loc_menu">
          <div
            v-show="showLocationMenu"
            class="location_menu_list"
          >
            <button
              v-for="item in contentLoc"
              :key="item"
              :class="['location_menu_item', { active: currentItem === item.item }]"
              @click="setLocation(item.id); showLocationMenu = false"
            >
              {{ item.city }}
            </button>
          </div>
        </transition>
      </template>
    </button>
  </div>
</template>

<script>

import { defineComponent } from 'vue'
export default defineComponent({
  name: 'LocationMenu',
  data () {
    return {
      showMobile: false,
      maxWidth: 1024,
      fileName: '',
      contentLoc: [],
      loadedFromFile: false,
      loadedLoc: false,
      phpScript: '/controller/get.php',
      currentItem: 0,
      showLocationMenu: false,
      coordinates: null,
      errorMessage: ''
    }
  },

  computed: {
    selectedLocation () {
      if (this.loadedLoc && this.contentLoc.length) {
        const locationItem = this.contentLoc.find(item => item.id === this.currentItem)
        if (locationItem) {
          return [locationItem.city, locationItem.phone1, 'tel:+' + locationItem.phone1.replace(/[^\d]/g, '')]
        }
      }
      return ['', '', '']
    }
  },

  created () {
    this.fileName = '/data/contacts.d'
    this.phpScript = '/php/get.php'
    this.loadContent(() => {
      this.contentLoc = [].concat(this.contentLoc.filter(e => e.visibility)) // Удаляем невидимые элементы
      let isLocalStorageCorrect = false
      if (localStorage.getItem('sever_location')) { // Если локация уже была установлена
        const ids = this.contentLoc.map(el => el.id)
        if (ids.includes(Number(localStorage.getItem('sever_location')))) { // Если ID локации существует в массиве contentLoc
          this.currentItem = Number(localStorage.getItem('sever_location'))
          isLocalStorageCorrect = true
        }
      }

      if (!isLocalStorageCorrect) {
        localStorage.removeItem('sever_location')
        // При первом запуске или если локация была удалена в CMS, выбираем первую локацию в списке по умолчанию
        if (this.contentLoc.length) {
          this.currentItem = this.contentLoc[0].id
          // localStorage.setItem('sever_location', this.currentItem)
        } else {
          this.loadedLoc = false
        }
      }
    })
    this.getLocation() // Получаем геолокацию пользователя
  },
  mounted () {
    this.onResize()
    window.addEventListener('resize', this.onResize)
  },
  beforeUnmount () {
    window.removeEventListener('resize', this.onResize)
  },

  methods: {
    getLocation () {
      if (!navigator.geolocation) {
        this.errorMessage = 'Геолокация не поддерживается браузером'
        return
      }

      const getPosition = function (options) {
        return new Promise(function (resolve, reject) {
          navigator.geolocation.getCurrentPosition(resolve, reject, options)
        })
      }
      getPosition({ maximumAge: 60000, timeout: 5000, enableHighAccuracy: true })
        .then((position) => {
          this.successCallback(position)
        })
        .catch((err) => {
          this.errorCallback(err)
        })
    },

    successCallback (position) {
      this.coordinates = {
        lat: position.coords.latitude,
        lng: position.coords.longitude
      }

      this.checkLocation()
    },

    errorCallback (error) {
      switch (error.code) {
        case error.PERMISSION_DENIED:
          this.errorMessage = 'Пользователь запретил определение геопозиции'
          break
        case error.POSITION_UNAVAILABLE:
          this.errorMessage = 'Информация о геопозиции недоступна'
          break
        case error.TIMEOUT:
          this.errorMessage = 'Запрос на определение геопозиции остановлен по таймауту'
          break
        case error.UNKNOWN_ERROR:
          this.errorMessage = 'Неизвестная ошибка'
          break
      }
      console.log(this.errorMessage)
    },

    getDistanceFromLatLonInKm (lat1, lon1, lat2, lon2) {
      function deg2rad (deg) {
        return deg * (Math.PI / 180)
      }
      const R = 6371 // Radius of the earth in km
      const dLat = deg2rad(lat2 - lat1) // deg2rad below
      const dLon = deg2rad(lon2 - lon1)
      const a =
        Math.sin(dLat / 2) * Math.sin(dLat / 2) +
        Math.cos(deg2rad(lat1)) * Math.cos(deg2rad(lat2)) *
        Math.sin(dLon / 2) * Math.sin(dLon / 2)
      const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a))
      const d = R * c // Distance in km
      return d
    },

    setLocation (value) {
      this.currentItem = value
      localStorage.setItem('sever_location', value)
    },

    // Определяем к какому городу пользователь ближе
    checkLocation () {
      if (localStorage.getItem('sever_location')) return // Если город уже выбран return
      console.log('Определяем локацию...')
      if (this.contentLoc) {
        this.currentItem = this.contentLoc[0].id
        let minDistance = 1000000
        let distance
        this.contentLoc.forEach(element => {
          distance = this.getDistanceFromLatLonInKm(element.latitude, element.longitude, this.coordinates.lat, this.coordinates.lng)
          console.log(element.city + ' ' + distance + 'км')
          if (distance < minDistance) {
            minDistance = distance
            this.currentItem = element.id
          }
        })
        localStorage.setItem('sever_location', this.currentItem)
      }
    },

    onResize () {
      this.showMobile = window.innerWidth < this.maxWidth
    },
    setLoaded () {
      this.loadedLoc = true
    },
    loadContent (dataSetter) {
      const dataForm = new FormData()
      dataForm.append('file', this.fileName)
      fetch(this.phpScript, {
        method: 'POST',
        body: dataForm
      })
        .then(async response => {
          const data = await response.json()
          if (!response.ok) {
            const error = (data && data.message) || response.statusText
            return Promise.reject(error)
          } else {
            this.contentLoc = data
            if (this.contentLoc) {
              this.loadedLoc = true
              if (dataSetter && typeof dataSetter === 'function') { // Запускаем дополнительную функцию обработки загруженных данных
                dataSetter()
              }
            } else {
              this.loadedLoc = false
            }
          }
        })
        .catch(error => {
          console.log('Произошла ошибка! ', error)
          // this.contentLoc = []
          this.loadedLoc = false
        })
    }
  }

})
</script>

<style scoped>

.blue_dot {
  width: 8px;
  height: 8px;
  border-radius: 5px;
  background-color: #5FB0CC;
  margin-right: 5px;
}

.arrow_down {
  width: 6px;
  height: 6px;
  margin-left: .2rem;
  border-right: 1.2px solid var(--font_color);
  border-bottom: 1.2px solid var(--font_color);
  transform: rotate(45deg);
}
.location_container {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: center;
  height: 100%;
  z-index: 1000;
  position: relative;
}

.location_container a {
  white-space: nowrap;
  /* border-bottom: 2px dotted; */
  /* width: 10em; */
}

.location_menu {
  position: relative;
  width: max-content;
  font-size: .9rem;
  text-align: right;
  z-index: 1000;
}

.location_menu img {
  width: 1.3rem;
}

.location_menu div:first-child p {
  /* название города*/
  font-size: .9rem;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  width: 100%;
  padding: .5rem 0;
}

.location_menu_list {
  position: absolute;
  top: 100%;
  right: 0;
  display: flex;
  flex-direction: column;
  transition: 700ms cubic-bezier(0.075, 0.82, 0.165, 1);
  overflow: hidden;
  /* border-radius: .5rem; */
}

.location_menu_item {
  font-size: .9rem;
  cursor: pointer;
  text-align: center;
  padding: .5rem 1rem;
  font-weight: 300;
  text-align: right;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  transition: 200ms;
  background-color: rgba(240, 240, 240, 0.98);
  border-bottom: .5px solid rgba(255, 255, 255, 1);
}

.location_menu_item:last-child {
  border-bottom: none;
}

.location_menu_item:hover {
  background-color: #5FB0CC;
  color: white;
  transition: 200ms;
}

.location_menu_item.active {
  font-weight: 900;
}
</style>
