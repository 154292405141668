<template>
  <div
    v-if="itsTime"
    class="loader_wrapper"
  >
    <div class="typing-indicator">
      <div class="typing-circle" />
      <div class="typing-circle" />
      <div class="typing-circle" />
      <div class="typing-shadow" />
      <div class="typing-shadow" />
      <div class="typing-shadow" />
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
export default defineComponent({
  name: 'CmsLoader',
  props: {
    color: {
      type: String,
      default: '#000'
    }
  },
  data () {
    return {
      itsTime: false
    }
  },
  mounted () {
    setTimeout(() => { this.itsTime = true }, 2000)
  }
})
</script>

<style scoped>

.loader_wrapper {
  min-width: 100%;
  min-height: 100%;
  display: grid;
  align-items: center;
  justify-items: center;
}

.loader_container {
  align-self: center;
  justify-self: center;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(50%, 50%);
}

.typing-indicator {
  width: 60px;
  height: 30px;
  position: relative;
  z-index: 4;
}

.typing-circle {
  width: 8px;
  height: 8px;
  position: absolute;
  border-radius: 50%;
  background-color: v-bind(color);
  left: 15%;
  transform-origin: 50%;
  animation: typing-circle7124 0.5s alternate infinite ease;
}

@keyframes typing-circle7124 {
  0% {
    top: 20px;
    height: 5px;
    border-radius: 50px 50px 25px 25px;
    transform: scaleX(1.7);
  }

  40% {
    height: 8px;
    border-radius: 50%;
    transform: scaleX(1);
  }

  100% {
    top: 0%;
  }
}

.typing-circle:nth-child(2) {
  left: 45%;
  animation-delay: 0.2s;
}

.typing-circle:nth-child(3) {
  left: auto;
  right: 15%;
  animation-delay: 0.3s;
}

.typing-shadow {
  width: 5px;
  height: 4px;
  border-radius: 50%;
  background-color: rgba(0, 0, 0, 0.2);
  position: absolute;
  top: 24px;
  transform-origin: 50%;
  z-index: 3;
  left: 15%;
  filter: blur(1px);
  animation: typing-shadow046 0.5s alternate infinite ease;
}

@keyframes typing-shadow046 {
  0% {
    transform: scaleX(1.5);
  }

  40% {
    transform: scaleX(1);
    opacity: 0.7;
  }

  100% {
    transform: scaleX(0.2);
    opacity: 0.4;
  }
}

.typing-shadow:nth-child(4) {
  left: 45%;
  animation-delay: 0.2s;
}

.typing-shadow:nth-child(5) {
  left: auto;
  right: 15%;
  animation-delay: 0.3s;
}
</style>
