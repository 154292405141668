<template>
    <transition
      v-show="isVisible"
      name="up-down"
    >
      <div
        v-if="loaded"
        class="wrapper_sub"
      >
        <div class="submenu_container">
          <template v-for="submenuItem in topMenuItems[topMenuItems.findIndex((el) => { return el.id === itemId })].items">
            <transition
              v-if="submenuItem.visible"
              :key="submenuItem.id"
              name="up-down"
            >
              <div class="item_wrapper">
                <div>
                  <router-link :to="'/product/' + submenuItem.id">
                    <h3>{{ submenuItem.text }}</h3>
                  </router-link>
                  <template v-for="item in submenuItem.items">
                    <p
                      v-if="item.visible"
                      :key="item.id"
                    >
                      <router-link :to="'/product/' + submenuItem.id + '/' + item.id">
                        {{ item.text }}
                      </router-link>
                    </p>
                  </template>
                </div>
              </div>
            </transition>
          </template>
        </div>
      </div>
    </transition>
    <div
      v-if="isSession"
      class="cms_edit_mode"
    >
      Сейчас запущена CMS
    </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import MainMenuMixin from '@/components/MainMenuMixin.vue'
export default defineComponent({
  name: 'SubMenu',
  mixins: [MainMenuMixin],
  props: {
    itemId: {
      type: Number,
      default: 0,
      required: true
    },
    isVisible: {
      type: Boolean,
      required: true
    }
  },
  mounted () {
    window.addEventListener('storage', (event) => {
      if (event.key === 'sessionStarted') {
        this.isSession = this.checkSession()
      }
    })
    this.loadJSON(() => {
      this.topMenuItems = this.content
    })
  }
})
</script>

<style scoped>
a {
  font-weight: 300;
  color: #2c3e50;
  /* text-decoration: none; */
}

a:active {
  color: #000000;
}

.cms_edit_mode {
  position: fixed;
  top: 0;
  left: 0;
  background-color: #ff00008b;
  color: white;
  font-size: .8em;
  padding: 1em;
}

.wrapper_sub {
  /* position: absolute;
  left: 0;
  top: var(--header_height); */
  width: 100%;
  display: flex;
  justify-content: center;
  box-shadow: 0px -10px 50px -7px rgba(34, 60, 80, 0.1) inset;
  z-index: 999;
}

.submenu_container {
  width: 100%;
  display: grid;
  text-align: left;
  white-space: normal;
  grid-template-columns: repeat(auto-fit, minmax(6rem, auto));
  gap: 2rem;
  font-size: .8rem;
  font-weight: 200;
  padding: 2rem 25%;
  background-color: rgba(255, 255, 255, 0.95);
}

.item_wrapper p {
  line-height: 1rem;
  padding: .25rem 0;
  white-space: normal;
}

h3 {
  margin-bottom: 1rem;
  height: 2rem;
}

</style>
