<template>
  <transition :name="isMobile ? 'bottom-top' : 'left-right'">
    <div
      :class="['wrapper', {wrapper_mobile: isMobile}]"
      @keyup.esc="$emit('cmsConfirmCanceled')"
    >
      <span v-if="!isMobile">Сохранить изменения?</span>
      <cms-button
        image="/img/icon_ok_white.svg"
        text="Сохранить"
        width="10rem"
        :class="[{_margin: !isMobile}]"
        @click="$emit('cmsConfirmOk')"
      />
      <cms-button
        image="/img/icon_cancel_white.svg"
        text="Отменить"
        width="10rem"
        :class="[{_margin: !isMobile}]"
        @click="$emit('cmsConfirmCanceled')"
      />
    </div>
  </transition>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import CmsButton from '@/components/CmsButton.vue'
export default defineComponent({
  name: 'CmsConfirm',
  components: {
    CmsButton
  },
  props: {
    visible: {
      type: Boolean,
      default: false
    },
    isMobile: {
      type: Boolean,
      default: false
    }
  },
  emits: [
    'cmsConfirmCanceled',
    'cmsConfirmOk'
  ]
})
</script>

<style scoped>

.wrapper {
  position: fixed;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 2.5rem 0 0 1.5rem;
  left: 0;
  top: 0;
  width: 16rem;
  height: 100%;
  background-color: var(--background_color) !important;
}

.wrapper_mobile {
  display: grid;
  grid-template-columns: 1fr 1fr;
  padding: 0;
  justify-items: center;
  left: 0;
  top: auto;
  bottom: 0;
  width: 100%;
  height: 6rem;
  box-shadow: 0px 2px 25px rgba(0, 0, 0, 0.2);
  z-index: 1;
}

._margin {
  margin-top: 1rem;
}

</style>
