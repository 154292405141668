import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router'

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    component: () => import('@/views/HomeView.vue'), // @ – "/src" alias
    meta: {
      title: 'home',
      layout: 'main-layout' as string
    }
  },
  {
    path: '/error',
    component: () => import('@/views/CmsErrorView.vue'),
    meta: {
      layout: 'main-layout' as string
    }
  },
  {
    path: '/cmserror',
    component: () => import('@/views/CmsErrorView.vue'),
    meta: {
      layout: 'cms-layout' as string
    }
  },
  {
    path: '/service',
    component: () => import('@/views/ServiceView.vue'),
    meta: {
      layout: 'main-layout' as string
    }
  },
  {
    path: '/lising',
    component: () => import('@/views/LisingView.vue'),
    meta: {
      layout: 'main-layout' as string
    }
  },
  {
    path: '/about',
    component: () => import('@/views/AboutView.vue'),
    meta: {
      layout: 'main-layout' as string
    }
  },
  {
    path: '/contacts',
    component: () => import('@/views/ContactsView.vue'),
    meta: {
      layout: 'main-layout' as string
    }
  },
  {
    path: '/order',
    component: () => import('@/views/OrderView.vue'),
    meta: {
      layout: 'main-layout' as string
    }
  },
{
    path: '/product/:idCategory',
    component: () => import('@/views/ProductView.vue'),
    meta: {
      layout: 'main-layout' as string
    }
  },
  {
    path: '/product/:idCategory/:idSubcategory',
    component: () => import('@/views/ProductView.vue'),
    meta: {
      layout: 'main-layout' as string
    }
  },
  {
    path: '/model/:idModel',
    component: () => import('@/views/ProductItemView.vue'),
    meta: {
      layout: 'main-layout' as string
    }
  },
  {
    path: '/cms/lonking',
    component: () => import('@/views/CmsLonking.vue'),
    meta: {
      layout: 'cms-layout' as string
    }
  },
  {
    path: '/cms/recycle',
    component: () => import('@/views/CmsProductView.vue'),
    meta: {
      layout: 'cms-layout' as string
    }
  },
  {
    path: '/cms',
    component: () => import('@/views/CmsView.vue'),
    meta: {
      layout: 'cms-layout' as string
    }
  },
  {
    path: '/cms/catalog',
    component: () => import('@/views/CmsCatalogView.vue'),
    meta: {
      layout: 'cms-layout' as string
    }
  },
  {
    path: '/cms/settings',
    component: () => import('@/views/CmsSettings.vue'),
    meta: {
      layout: 'cms-layout' as string
    }
  },
  {
    path: '/cms/catalog/:idMenuItem',
    component: () => import('@/views/CmsSubmenuItemView.vue'),
    meta: {
      layout: 'cms-layout' as string
    }
  },
  {
    path: '/cms/product/:idCategory',
    component: () => import('@/views/CmsProductView.vue'),
    meta: {
      layout: 'cms-layout' as string
    }
  },
  {
    path: '/cms/product/:idCategory/:idSubcategory',
    component: () => import('@/views/CmsProductView.vue'),
    meta: {
      layout: 'cms-layout' as string
    }
  },
  {
    path: '/cms/model',
    component: () => import('@/views/CmsProductItemView.vue'),
    meta: {
      layout: 'cms-layout' as string
    }
  },
  {
    path: '/cms/model/:id',
    component: () => import('@/views/CmsProductItemView.vue'),
    meta: {
      layout: 'cms-layout' as string
    }
  },
  {
    path: '/cms/users',
    component: () => import('@/views/CmsUsersView.vue'),
    meta: {
      layout: 'cms-layout' as string
    }
  },
  {
    path: '/cms/messages',
    component: () => import('@/views/CmsMessagesView.vue'),
    meta: {
      layout: 'cms-layout' as string
    }
  },
  {
    path: '/cms/backup',
    component: () => import('@/views/CmsBackupView.vue'),
    meta: {
      layout: 'cms-layout' as string
    }
  },
  {
    path: '/cms/service',
    component: () => import('@/views/CmsServiceView.vue'),
    meta: {
      layout: 'cms-layout' as string
    }
  },
  {
    path: '/cms/lising',
    component: () => import('@/views/CmsLisingView.vue'),
    meta: {
      layout: 'cms-layout' as string
    }
  },
  {
    path: '/cms/about',
    component: () => import('@/views/CmsAboutView.vue'),
    meta: {
      layout: 'cms-layout' as string
    }
  },
  {
    path: '/cms/contacts',
    component: () => import('@/views/CmsContactsView.vue'),
    meta: {
      layout: 'cms-layout' as string
    }
  },
  {
    path: '/cms/footer',
    component: () => import('@/views/CmsFooterView.vue'),
    meta: {
      layout: 'cms-layout' as string
    }
  }
]

const router = createRouter({
  history: createWebHistory(),
  routes
})

export default router
