import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createTextVNode as _createTextVNode, createCommentVNode as _createCommentVNode, Transition as _Transition, createBlock as _createBlock, vShow as _vShow, withDirectives as _withDirectives, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = (n: any) => (_pushScopeId("data-v-abbbd610"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 0,
  class: "wrapper_sub"
}
const _hoisted_2 = { class: "submenu_container" }
const _hoisted_3 = { class: "item_wrapper" }
const _hoisted_4 = {
  key: 0,
  class: "cms_edit_mode"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_link = _resolveComponent("router-link")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _withDirectives(_createVNode(_Transition, { name: "up-down" }, {
      default: _withCtx(() => [
        (_ctx.loaded)
          ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
              _createElementVNode("div", _hoisted_2, [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.topMenuItems[_ctx.topMenuItems.findIndex((el) => { return el.id === _ctx.itemId })].items, (submenuItem) => {
                  return (_openBlock(), _createElementBlock(_Fragment, null, [
                    (submenuItem.visible)
                      ? (_openBlock(), _createBlock(_Transition, {
                          key: submenuItem.id,
                          name: "up-down"
                        }, {
                          default: _withCtx(() => [
                            _createElementVNode("div", _hoisted_3, [
                              _createElementVNode("div", null, [
                                _createVNode(_component_router_link, {
                                  to: '/product/' + submenuItem.id
                                }, {
                                  default: _withCtx(() => [
                                    _createElementVNode("h3", null, _toDisplayString(submenuItem.text), 1)
                                  ]),
                                  _: 2
                                }, 1032, ["to"]),
                                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(submenuItem.items, (item) => {
                                  return (_openBlock(), _createElementBlock(_Fragment, null, [
                                    (item.visible)
                                      ? (_openBlock(), _createElementBlock("p", {
                                          key: item.id
                                        }, [
                                          _createVNode(_component_router_link, {
                                            to: '/product/' + submenuItem.id + '/' + item.id
                                          }, {
                                            default: _withCtx(() => [
                                              _createTextVNode(_toDisplayString(item.text), 1)
                                            ]),
                                            _: 2
                                          }, 1032, ["to"])
                                        ]))
                                      : _createCommentVNode("", true)
                                  ], 64))
                                }), 256))
                              ])
                            ])
                          ]),
                          _: 2
                        }, 1024))
                      : _createCommentVNode("", true)
                  ], 64))
                }), 256))
              ])
            ]))
          : _createCommentVNode("", true)
      ]),
      _: 1
    }, 512), [
      [_vShow, _ctx.isVisible]
    ]),
    (_ctx.isSession)
      ? (_openBlock(), _createElementBlock("div", _hoisted_4, " Сейчас запущена CMS "))
      : _createCommentVNode("", true)
  ], 64))
}