<template>
    <img
      v-if="!showMenu"
      src="/img/icon_dots.svg"
      alt=""
      class="icon_image"
      @click="setActive"
    >
    <transition name="burger_menu">
      <nav v-if="loaded && showMenu">
        <div
          class="left_arrow"
          @click="setActive"
        />
        <template v-for="menuItem in topMenuItems">
          <div
            v-if="menuItem.visible"
            :key="menuItem.id"
            class="burger-menu-item"
          >
            <!-- Пункты меню без подменю -->
            <router-link
              v-if="menuItem.items.length === 0"
              :to="menuItem.path"
            >
              <div class="main_menu_item">
                {{ menuItem.text }}
              </div>
            </router-link>
            <!-- Пункты меню с подменю -->
            <template v-else>
              <router-link :to="homePath">
                <div class="main_menu_item">
                  {{ menuItem.text }}
                </div>
              </router-link>
              <div class="submenu">
                <template v-for="submenuItem in menuItem.items">
                  <router-link
                    v-if="submenuItem.visible"
                    :key="submenuItem.id"
                    :to="'/product/' + submenuItem.id"
                  >
                    <div class="submenu_item">
                      {{ submenuItem.text }}
                    </div>
                  </router-link>
                </template>
              </div>
            </template>
          </div>
        </template>
        <router-link to="/order">
          <div class="main_menu_item">
            Заявка на спецтехнику
          </div>
        </router-link>
      </nav>
    </transition>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import MainMenuMixin from '@/components/MainMenuMixin.vue'
import MainMixin from '@/components/MainMixin.vue'
export default defineComponent({
  name: 'MainMenuBurger',
  mixins: [MainMixin, MainMenuMixin],
  emits: ['showBurgerMenu', 'hideBurgerMenu'],
  data () {
    return {
      curentId: 0, // id активного пункта меню, в котором есть подменю
      homePath: '/',
      showMenu: false
    }
  },
  watch: {
    '$route.path' () {
      this.showMenu = false // Убираем меню при переходе по ссылке
      this.$emit('hideBurgerMenu') // Генерируем событие для восстановления прокрутки страниц
    }
  },
  created () {
    this.loadJSON(() => {
      this.topMenuItems = this.content
    })
  },
  methods: {
    setActive () {
      this.showMenu = !this.showMenu
      if (this.showMenu) {
        this.$emit('showBurgerMenu')
      } else {
        this.$emit('hideBurgerMenu') // Генерируем событие для восстановления прокрутки страниц
      }
    }
  }
})
</script>

<style scoped>

.icon_image {
  position: fixed;
  width: 3rem;
  align-self: center;
  left: 0;
  top: 1.5rem;
  cursor: pointer;
  z-index: 1;
}

.left_arrow {
  cursor: pointer;
  position: fixed;
  top: 2rem;
  left: 2rem;
  width: 1.3rem;
  height: 1.3rem;
  border-left: 4px solid #000;
  border-bottom: 4px solid #000;
  transform: rotate(45deg);
}

nav {
  position: absolute;
  font-size: 1.3rem;
  width: 100%;
  min-height: 100%;
  padding: 5rem 3rem 3rem 3rem;
  /* background: linear-gradient(70deg, #005c97, #3895b4); */
  /* background: linear-gradient(70deg, #003758, #3895b4); */
  background-color: #f3f3f3;
  /* opacity: .95; */
  /* box-shadow: 10px 0 50px -7px rgba(34,60,80,.3); */
  box-shadow: 30px -50px 100px -7px rgba(34,60,80,.1) inset;
  color: #eee;
  z-index: 1;
  line-height: 3rem;
}

nav a {
  font-size: 1.3rem;
  font-weight: 400;
}

nav a.router-link-exact-active {
  font-weight: bolder;
  position: relative;
}

/* nav a.router-link-exact-active::before {
  display: block;
  position: absolute;
  top: 1.1rem;
  left: -1.5rem;
  height: 0.6rem;
  width: 0.6rem;
  content: "";
  border-radius: 0.5rem;
  background-color: #009bc2;
} */

.burger-menu-item {
  border-bottom: .5px solid #c6c6c6;
}

.burger-menu-item:last-child {
  border-bottom: none;
}

.submenu {
  display: grid;
  gap: 1rem;
  padding: 0.5rem 0 1rem 2rem;
}

.main_menu_item {
  /* white-space: nowrap; */
  line-height: 4rem;
}

.submenu_item {
  /* white-space: nowrap; */
  line-height: 2rem;
}

.submenu_wrapper {
  position: absolute;
  width: 100%;
  left: 0;
  top: var(--header_height);
  overflow: hidden;
}

.white_bg {
  /* background-color: rgba(255, 255, 255, 0.95); */
  font-weight: 300;
  /* padding: 0 20rem; */
}

</style>
