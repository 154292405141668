import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, normalizeClass as _normalizeClass, createVNode as _createVNode, withKeys as _withKeys, createElementVNode as _createElementVNode, Transition as _Transition, withCtx as _withCtx, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = (n: any) => (_pushScopeId("data-v-2483dd7c"),n=n(),_popScopeId(),n)
const _hoisted_1 = { key: 0 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_cms_button = _resolveComponent("cms-button")!

  return (_openBlock(), _createBlock(_Transition, {
    name: _ctx.isMobile ? 'bottom-top' : 'left-right'
  }, {
    default: _withCtx(() => [
      _createElementVNode("div", {
        class: _normalizeClass(['wrapper', {wrapper_mobile: _ctx.isMobile}]),
        onKeyup: _cache[2] || (_cache[2] = _withKeys(($event: any) => (_ctx.$emit('cmsConfirmCanceled')), ["esc"]))
      }, [
        (!_ctx.isMobile)
          ? (_openBlock(), _createElementBlock("span", _hoisted_1, "Сохранить изменения?"))
          : _createCommentVNode("", true),
        _createVNode(_component_cms_button, {
          image: "/img/icon_ok_white.svg",
          text: "Сохранить",
          width: "10rem",
          class: _normalizeClass([{_margin: !_ctx.isMobile}]),
          onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('cmsConfirmOk')))
        }, null, 8, ["class"]),
        _createVNode(_component_cms_button, {
          image: "/img/icon_cancel_white.svg",
          text: "Отменить",
          width: "10rem",
          class: _normalizeClass([{_margin: !_ctx.isMobile}]),
          onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.$emit('cmsConfirmCanceled')))
        }, null, 8, ["class"])
      ], 34)
    ]),
    _: 1
  }, 8, ["name"]))
}