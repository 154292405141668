<template>
  <div class="footer">
    <div />
    <!-- Север Инжиниринг &copy; 2023 -->
    <div v-html="footerText" />
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'

import MainMixin from '@/components/MainMixin.vue'
export default defineComponent({
  name: 'MainFooter',
  mixins: [MainMixin],
  data () {
    return {
      dataFile: '/data/footer.d',
      footerText: ''
    }
  },
  created () {
    this.loadTextArr(this.dataFile, () => {
      this.footerText = this.customContent
    })
  }

})
</script>

<style scoped>
.footer {
  display: grid;
  grid-template-columns: 1fr auto;
  padding: 1rem 3rem;
  font-size: .8rem;
}
</style>
