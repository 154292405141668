import { createElementVNode as _createElementVNode, normalizeStyle as _normalizeStyle, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = (n: any) => (_pushScopeId("data-v-75c21d4c"),n=n(),_popScopeId(),n)
const _hoisted_1 = ["src"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("button", null, [
    (_ctx.image)
      ? (_openBlock(), _createElementBlock("div", {
          key: 0,
          class: "button_container",
          style: _normalizeStyle({width: _ctx.width})
        }, [
          (_ctx.image)
            ? (_openBlock(), _createElementBlock("div", {
                key: 0,
                style: _normalizeStyle({backgroundColor: _ctx.iconColor})
              }, [
                _createElementVNode("img", {
                  src: _ctx.image,
                  alt: ""
                }, null, 8, _hoisted_1)
              ], 4))
            : _createCommentVNode("", true),
          _createElementVNode("div", null, _toDisplayString(_ctx.text), 1)
        ], 4))
      : (_openBlock(), _createElementBlock("div", {
          key: 1,
          class: "button_container_no_image",
          style: _normalizeStyle({width: _ctx.width})
        }, [
          _createElementVNode("div", null, _toDisplayString(_ctx.text), 1)
        ], 4))
  ]))
}