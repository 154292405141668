<template>
    <cms-confirm
      v-if="isChanged"
      :is-mobile="showMobile"
      @cms-confirm-canceled="setCanceled"
      @cms-confirm-ok="setOk"
    />
    <div :class="['cms_wrapper', {cms_wrapper_mobile: showMobile}, {_overflow_hidden: isBurgerMenuActive}]">
      <div
        v-if="!showMobile"
        class="cms_left_menu"
      >
        <transition name="fade">
          <div v-if="!isChanged">
            <!-- Уменьшенный логотип всегда для CMS -->
            <main-logo
              path="/cms"
              :is-mobile="true"
            />
            <!-- Обновляем компонент (при загрузке backup например) -->
            <cms-left-menu :key="String(leftMenuUpdate)" />
          </div>
        </transition>
      </div>
      <div :class="['cms_content', {cms_content_mobile: showMobile}]">
        <!-- Компонент будет обновляться при любом изменении path, например если на странице изменился передаваемый параметр /catalog/:idMenuitem -->
        <router-view
          :key="$route.fullPath"
          :is-canceled="isCanceled"
          :is-ok="isOk"
          @cms-input-changed="setChanged"
          @data-saved="leftMenuUpdate = !leftMenuUpdate"
        />
      </div>
    </div>
    <cms-right-menu
      v-if="!isChanged"
      :user="state.userId"
      :is-mobile="showMobile"
    />
    <template v-if="showMobile">
      <cms-left-menu-burger
        v-if="!isChanged"
        :key="String(leftMenuUpdate)"
        @show-burger-menu="isBurgerMenuActive = true"
        @hide-burger-menu="isBurgerMenuActive = false"
        @hide-mobile="isBurgerMenuActive = false"
      />
      <div :class="['cms_header',{blocked: isChanged}]">
        <main-logo path="/cms" />
      </div>
    </template>
</template>

<script lang="ts">
import MainLogo from '@/components/MainLogo.vue'
import MainMixin from '@/components/MainMixin.vue'
import CmsLeftMenu from '@/components/CmsLeftMenu.vue'
import CmsLeftMenuBurger from '@/components/CmsLeftMenuBurger.vue'
import CmsRightMenu from '@/components/CmsRightMenu.vue'
import CmsConfirm from '@/components/CmsConfirm.vue'
// import router from '@/router'
import { defineComponent, onMounted, reactive, ref } from 'vue'
export default defineComponent({
  components: {
    MainLogo, CmsLeftMenu, CmsLeftMenuBurger, CmsRightMenu, CmsConfirm
  },
  mixins: [MainMixin],
  setup () {
    const state = reactive({
      userId: ref('')
    })
    onMounted(async () => {
      // Проверяем создана ли PHP-сессия
      const response = await fetch('/php/get_session_info.php')
      state.userId = await response.text()
      if (state.userId.length) localStorage.setItem('sessionStarted', 'true')
      window.onbeforeunload = () => { localStorage.removeItem('sessionStarted') }
    })
    return {
      state
    }
  },
  data () {
    return {
      isBurgerMenuActive: false,
      isOk: false,
      isCanceled: false,
      isChanged: false,
      leftMenuUpdate: false
    }
  },
  created () {
    window.document.title = 'CMS Север Инжиниринг'
  },
  beforeUnmount () {
    console.log('#######')
    localStorage.removeItem('sessionStarted')
  },
  methods: {
    setChanged () {
      this.isChanged = true
      this.isOk = false
      this.isCanceled = false
    },
    setOk () {
      this.$emit('cmsConfirmOk')
      this.isChanged = false
      this.isOk = true
      this.isCanceled = false
    },
    setCanceled () {
      this.$emit('cmsConfirmCanceled')
      this.isChanged = false
      this.isOk = false
      this.isCanceled = true
    }
  }
})
</script>

<style scoped>

.cms_header {
  position: fixed;
  top: 0;
  width: 100%;
  display: grid;
  height: 6rem;
  background-color: #fafafa;
  box-shadow: 0 0 20px 0 rgba(34, 60, 80, 0.1);
}

.cms_wrapper {
  display: grid;
  grid-template-columns: 18rem 1fr;
  justify-content: center;
  width: calc(100% - 6rem);
  height: 100vh;
}

.cms_left_menu {
  padding: 2rem;
}

.cms_content {
  box-shadow: 0 0 20px 0 rgba(34, 60, 80, 0.1);
  padding-bottom: 6rem;
  z-index: 1000;
}

._overflow_hidden {
  overflow: hidden;
  z-index: 0;
  /* margin-left: 30%; */
  /* margin-right: -30%; */
  /* transform: translateX(50%); */
  /* transition: all 0.4s cubic-bezier(0.075, 0.82, 0.165, 1); */
}

/* ============ */
/*    Mobile    */
/* ============ */

.blocked {
  opacity: .5;
  /* Делаем элемент неактивным */
  pointer-events: none;
}

.cms_wrapper_mobile {
  display: block;
  width: 100%;
  padding-top: 0;
  padding-bottom: 6rem;
  /* overflow: scroll; */
  /* grid-template-columns: 1fr;
  width: 100%; */
}

.cms_content_mobile {
  box-shadow: none;
  padding-top: 6rem;
}
</style>
