import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, createTextVNode as _createTextVNode, withKeys as _withKeys, normalizeClass as _normalizeClass, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = (n: any) => (_pushScopeId("data-v-562c53b5"),n=n(),_popScopeId(),n)
const _hoisted_1 = { key: 0 }
const _hoisted_2 = { class: "main_menu_item" }
const _hoisted_3 = ["onKeyup", "onTouchstart", "onMouseenter", "onMouseleave"]
const _hoisted_4 = { class: "submenu_wrapper" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_link = _resolveComponent("router-link")!
  const _component_sub_menu = _resolveComponent("sub-menu")!

  return (_ctx.loaded)
    ? (_openBlock(), _createElementBlock("nav", _hoisted_1, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.topMenuItems, (menuItem) => {
          return (_openBlock(), _createElementBlock(_Fragment, null, [
            (menuItem.visible)
              ? (_openBlock(), _createElementBlock("div", {
                  key: menuItem.id
                }, [
                  (!menuItem.items.length)
                    ? (_openBlock(), _createBlock(_component_router_link, {
                        key: 0,
                        to: menuItem.path
                      }, {
                        default: _withCtx(() => [
                          _createElementVNode("div", _hoisted_2, _toDisplayString(menuItem.text), 1)
                        ]),
                        _: 2
                      }, 1032, ["to"]))
                    : (_openBlock(), _createElementBlock("button", {
                        key: 1,
                        class: _normalizeClass(['main_menu_item', { white_bg : _ctx.submenuVisible && (menuItem.id === _ctx.curentId) }]),
                        onKeyup: _withKeys(($event: any) => {_ctx.submenuVisible = !_ctx.submenuVisible; _ctx.curentId = menuItem.id}, ["enter"]),
                        onTouchstart: ($event: any) => {_ctx.submenuVisible = !_ctx.submenuVisible; _ctx.curentId = menuItem.id},
                        onMouseenter: ($event: any) => {_ctx.submenuVisible = true; _ctx.curentId = menuItem.id},
                        onMouseleave: ($event: any) => {_ctx.submenuVisible = false; _ctx.curentId = menuItem.id}
                      }, [
                        _createTextVNode(_toDisplayString(menuItem.text) + " ", 1),
                        _createElementVNode("div", _hoisted_4, [
                          _createVNode(_component_sub_menu, {
                            "item-id": menuItem.id,
                            "is-visible": _ctx.submenuVisible && (menuItem.id === _ctx.curentId)
                          }, null, 8, ["item-id", "is-visible"])
                        ])
                      ], 42, _hoisted_3))
                ]))
              : _createCommentVNode("", true)
          ], 64))
        }), 256))
      ]))
    : _createCommentVNode("", true)
}