<script lang="ts">
import { defineComponent, ref } from 'vue'
export interface Order {
  id: number;
  type: string;
  name: string;
  image: string;
  manufacturersImage: string;
}
export default defineComponent({
  name: 'MainMixin',
  emits: ['showMobile', 'hideMobile', 'dataSaved', 'customDataSaved'],
  data () {
    return {
      showMobile: false,
      maxWidth: 1024,
      maxWidthPx: '1024px',
      fontSize: '16px',
      fileName: '',
      content: ref(), //       Массив для загрузки массива topMenuItems
      customContent: ref(), // Массив для загрузки произвольных данных из файла
      loaded: false,
      contentLoaded: false,
      saved: false,
      contentSaved: false,
      isSession: false,
      orders: [] as Order[],
      ordersCount: 0 as number
    }
  },
  mounted () {
    this.orders = JSON.parse(localStorage.getItem('orders') || '[]')
    this.ordersCount = this.orders.length

    this.onResize()
    window.addEventListener('resize', this.onResize)
    this.isSession = this.checkSession()
  },
  beforeUnmount () {
    window.removeEventListener('resize', this.onResize)
  },
  watch: {
    showMobile: function (newValue: boolean) {
      console.log('font-size =', newValue ? '12px' : '16px');
      const root: HTMLElement = document.documentElement;    
      root.style.fontSize = newValue ? '12px' : '16px';
    }  
  },
  methods: {
    onResize () {
      const oldVal = this.showMobile
      this.showMobile = window.innerWidth < this.maxWidth
      if (!oldVal && this.showMobile) this.$emit('showMobile')
      if (oldVal && !this.showMobile) this.$emit('hideMobile')
    },
    setLoaded () {
      this.loaded = true
    },
    isFile (file: string | undefined) {
      return (file !== undefined) && (file !== '')
    },
    newId (arr: number[]): number {
      let max = 0
      arr.forEach(element => {
        if (element > max) max = element
      })
      return max + 1
    },
    // loadJSON (dataSetter?: () => void, errorSetter?: () => void) {
    //   this.loaded = false
    //   const dataForm = new FormData()
    //   dataForm.append('file', this.fileName)

    //   const xhr = new XMLHttpRequest()
    //   xhr.open('POST', '/php/get.php', false)
    //   xhr.send(dataForm)

    //   if (xhr.status === 200) {
    //     const data = xhr.response
    //     if (data !== undefined) {
    //       this.content = JSON.parse(data)
    //       if (dataSetter) { dataSetter() } // Запускаем дополнительную функцию
    //     } else {
    //       console.log('Error: ' + xhr.statusText)
    //       if (errorSetter) { errorSetter() } // Запускаем функцию обработки контента
    //       this.content = []
    //     }
    //   }
    //   this.loaded = true
    // },
    async loadJSON (dataSetter?: () => void, errorSetter?: () => void) {
      this.loaded = false
      const dataForm = new FormData()
      dataForm.append('file', this.fileName)
      await fetch('/php/get.php', {
        method: 'POST',
        body: dataForm
      })
        .then(async response => {
          const data = await response.json()
          if (!response.ok) {
            const error = (data && data.message) || response.statusText
            this.loaded = true
            console.log('Произошла ошибка !response.ok ', error)
            // return Promise.reject(error)
          } else {
            if (data !== undefined) {
              this.content = JSON.parse(JSON.stringify(data))
              if (dataSetter) { dataSetter() }// Запускаем дополнительную функцию обработки загруженных данных
            } else {
              this.content = []
            }
            this.loaded = true
          }
        })
        .catch(error => {
          console.log('Произошла ошибка! ', error)
          this.content = []
          if (errorSetter) { errorSetter() } // Запускаем функцию обработки ошибки
          this.loaded = true
        })
    },
    loadJSONArr (fileName: string, dataSetter?: () => void, errorSetter?: () => void) {
      this.contentLoaded = false
      const dataForm = new FormData()
      dataForm.append('file', fileName)
      fetch('/php/get.php', {
        method: 'POST',
        body: dataForm
      })
        .then(async response => {
          const data = await response.json()
          if (!response.ok) {
            const error = (data && data.message) || response.statusText
            console.log('Произошла ошибка !response.ok ', error)
            return Promise.reject(error)
          } else {
            if (data !== undefined) {
              this.customContent = data
              if (dataSetter) dataSetter() // Запускаем дополнительную функцию обработки загруженных данных
            }
            this.contentLoaded = true
          }
        })
        .catch(error => {
          console.log('Произошла ошибка! ', error)
          this.customContent = []
          if (errorSetter) errorSetter()
          this.contentLoaded = true
        })
    },
    loadTextArr (fileName: string, dataSetter?: () => void, errorSetter?: () => void) {
      this.contentLoaded = false
      const dataForm = new FormData()
      dataForm.append('file', fileName)
      fetch('/php/get.php', {
        method: 'POST',
        body: dataForm
      })
        .then(async response => {
          const data = await response.text()
          if (!response.ok) {
            const error = (data) || response.statusText
            console.log('Произошла ошибка !response.ok ', error)
            return Promise.reject(error)
          } else {
            if (data !== undefined) {
              this.customContent = data
              if (dataSetter) dataSetter() // Запускаем дополнительную функцию обработки загруженных данных
            }
            this.contentLoaded = true
          }
        })
        .catch(error => {
          console.log('Произошла ошибка! ', error)
          this.customContent = []
          if (errorSetter) errorSetter()
          this.contentLoaded = true
        })
    },
    putJSON () {
      this.saved = false
      const dataForm = new FormData()
      dataForm.append('file', this.fileName)
      dataForm.append('content', JSON.stringify(this.content))
      fetch('/php/save.php', {
        method: 'POST',
        body: dataForm
      }).then(async response => {
        const data = await response.json()
        if (!response.ok) {
          const error = (data && data.message) || response.statusText
          return Promise.reject(error)
        } else {
          this.saved = true
          this.$emit('dataSaved')
          // this.contentLoaded = JSON.parse(JSON.stringify(this.content)) // Если запись сделана сохраняем копию content
        }
      }).catch(error => {
        console.log('Произошла ошибка! ', error)
      })
    },
    putJSONArr (fileName: string, contentJSON: string) {
      this.contentSaved = false
      const dataForm = new FormData()
      dataForm.append('file', fileName)
      dataForm.append('content', contentJSON)
      fetch('/php/save.php', {
        method: 'POST',
        body: dataForm
      }).then(async response => {
        const data = await response.json()
        if (!response.ok) {
          const error = (data && data.message) || response.statusText
          return Promise.reject(error)
        } else {
          this.contentSaved = true
          this.$emit('customDataSaved')
          console.log('Данные сохранены в файл ' + fileName.split('/').pop())
        }
      }).catch(error => {
        console.log('Произошла ошибка! ', error)
      })
    },
    checkSession (): boolean {
      // Проверяем запущена ли PHP-сессия в CMS
      if (localStorage.getItem('sessionStarted') === 'true') return true
      return false
    },
    deleteSession () {
      // Удаляем переменную о PHP-сессия в CMS
      localStorage.removeItem('sessionStarted')
    }
  }
})
</script>
