import { resolveComponent as _resolveComponent, createVNode as _createVNode, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, Transition as _Transition, Fragment as _Fragment, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = (n: any) => (_pushScopeId("data-v-18d03c5d"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "main_wrapper" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_main_header = _resolveComponent("main-header")!
  const _component_router_view = _resolveComponent("router-view")!
  const _component_wrapper = _resolveComponent("wrapper")!
  const _component_main_footer = _resolveComponent("main-footer")!
  const _component_image_viewer = _resolveComponent("image-viewer")!
  const _component_scroll_up = _resolveComponent("scroll-up")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", {
        class: _normalizeClass(['main_container', {_overflow_hidden: $data.isBurgerMenuActive || $data.isImageViewerActive}])
      }, [
        _createVNode(_component_main_header, {
          "is-mobile": _ctx.showMobile,
          "number-of-orders": _ctx.ordersCount,
          onShowBurgerMenu: _cache[0] || (_cache[0] = ($event: any) => ($data.isBurgerMenuActive = true)),
          onHideBurgerMenu: _cache[1] || (_cache[1] = ($event: any) => ($data.isBurgerMenuActive = false)),
          onHideMobile: _cache[2] || (_cache[2] = ($event: any) => ($data.isBurgerMenuActive = false))
        }, null, 8, ["is-mobile", "number-of-orders"]),
        _createVNode(_component_wrapper, {
          class: _normalizeClass({wrapper_mobile: _ctx.showMobile && $data.isBurgerMenuActive})
        }, {
          default: _withCtx(() => [
            _createElementVNode("div", {
              class: _normalizeClass([{mobile: _ctx.showMobile}])
            }, [
              _createVNode(_component_router_view, {
                "is-image-viewer-close": $data.isImageViewerActive || $data.isBurgerMenuActive,
                "is-image-viewer-prev": $data.isImageViewerPrev,
                "is-image-viewer-next": $data.isImageViewerNext,
                "is-burger-menu-active": $data.isBurgerMenuActive,
                onImageViewerActive: _cache[3] || (_cache[3] = (data) => {
                $data.ivData = data
                $data.isImageViewerActive = true
              }),
                onImageViewerChange: _cache[4] || (_cache[4] = (data) => {
                $data.ivData = data
                $data.isImageViewerPrev = false
                $data.isImageViewerNext = false
              }),
                onUpdateOrders: _cache[5] || (_cache[5] = (data) => {
                if (_ctx.ordersCount !== data) _ctx.ordersCount = data
              })
              }, null, 8, ["is-image-viewer-close", "is-image-viewer-prev", "is-image-viewer-next", "is-burger-menu-active"])
            ], 2)
          ]),
          _: 1
        }, 8, ["class"]),
        _createVNode(_component_main_footer)
      ], 2),
      _createVNode(_Transition, { name: "fade" }, {
        default: _withCtx(() => [
          ($data.isImageViewerActive)
            ? (_openBlock(), _createBlock(_component_image_viewer, {
                key: 0,
                file: $data.ivData.file,
                "number-of-images": $data.ivData.length,
                "active-index": $data.ivData.index,
                "show-mobile": _ctx.showMobile,
                onImageViewerClose: _cache[6] || (_cache[6] = ($event: any) => {$data.isImageViewerActive = false; $data.isImageViewerPrev = false; $data.isImageViewerNext = false}),
                onImageViewerPrev: _cache[7] || (_cache[7] = ($event: any) => {$data.isImageViewerPrev = true; $data.isImageViewerNext = false}),
                onImageViewerNext: _cache[8] || (_cache[8] = ($event: any) => {$data.isImageViewerNext = true; $data.isImageViewerPrev = false})
              }, null, 8, ["file", "number-of-images", "active-index", "show-mobile"]))
            : _createCommentVNode("", true)
        ]),
        _: 1
      })
    ]),
    (!_ctx.showMobile)
      ? (_openBlock(), _createBlock(_component_scroll_up, { key: 0 }))
      : _createCommentVNode("", true)
  ], 64))
}