<template>
    <div class="main_wrapper">
      <div :class="['main_container', {_overflow_hidden: isBurgerMenuActive || isImageViewerActive}]">
        <main-header
          :is-mobile="showMobile"
          :number-of-orders="ordersCount"
          @show-burger-menu="isBurgerMenuActive = true"
          @hide-burger-menu="isBurgerMenuActive = false"
          @hide-mobile="isBurgerMenuActive = false"
        />
        <wrapper :class="{wrapper_mobile: showMobile && isBurgerMenuActive}">
          <div :class="[{mobile: showMobile}]">
            <router-view
              :is-image-viewer-close="isImageViewerActive || isBurgerMenuActive"
              :is-image-viewer-prev="isImageViewerPrev"
              :is-image-viewer-next="isImageViewerNext"
              :is-burger-menu-active="isBurgerMenuActive"
              @image-viewer-active="(data: imageViewerData) => {
                ivData = data
                isImageViewerActive = true
              }"
              @image-viewer-change="(data: imageViewerData) => {
                ivData = data
                isImageViewerPrev = false
                isImageViewerNext = false
              }"
              @update-orders="(data: number) => {
                if (ordersCount !== data) ordersCount = data
              }"
            />
          </div>
        </wrapper>
        <main-footer />
      </div>
      <transition name="fade">
        <image-viewer
          v-if="isImageViewerActive"
          :file="ivData.file"
          :number-of-images="ivData.length"
          :active-index="ivData.index"
          :show-mobile="showMobile"
          @image-viewer-close="isImageViewerActive = false; isImageViewerPrev = false; isImageViewerNext = false"
          @image-viewer-prev="isImageViewerPrev = true; isImageViewerNext = false"
          @image-viewer-next="isImageViewerNext = true; isImageViewerPrev = false"
        />
      </transition>
    </div>
    <scroll-up v-if="!showMobile" />
</template>

<script lang="ts">
import MainMixin from '@/components/MainMixin.vue'
import MainHeader from '@/components/MainHeader.vue'
import MainFooter from '@/components/MainFooter.vue'
import ScrollUp from '@/components/ScrollUp.vue'
import ImageViewer from '@/components/ImageViewer.vue'

type imageViewerData = {
  file: string;
  length: number;
  index: number;
}
export default {
  components: {
    MainHeader, MainFooter, ScrollUp, ImageViewer
  },
  mixins: [MainMixin],
  data () {
    return {
      isBurgerMenuActive: false,
      isImageViewerActive: false,
      isImageViewerPrev: false,
      isImageViewerNext: false,
      ivData: {} as imageViewerData,
    }
  }
}
</script>
<style scoped>

.wrapper_mobile {
  width: 100%;
  height: 100%;
  overflow: hidden;
}

.mobile {
  display: block;
  margin-top: 6rem;
  transition: all 0.3s cubic-bezier(0.075, 0.82, 0.165, 1);
}

._overflow_hidden {
  overflow: hidden;
  transition: all 0.4s cubic-bezier(0.075, 0.82, 0.165, 1);
}
</style>
