<script lang="ts">
import { defineComponent } from 'vue'
import MainMixin from '@/components/MainMixin.vue'
export type typeCategoryItem = {
  id: number,
  visible: boolean,
  text: string,
  image: string
}
export type typeFilterValue = {
  id: number,
  visible: boolean,
  checked: boolean,
  text: string,
  products: number[]
}
export type typeFilter = {
  id: number,
  visible: boolean,
  text: string,
  values: typeFilterValue[],
  subcategories: number[]
}
export type typeMenuItem = {
  id: number,
  visible: boolean,
  text: string,
  image: string,
  items: typeCategoryItem[],
  filters: typeFilter[],
  desc: string
}
export type typeFeature = {
  id: number,
  visible: boolean,
  type: string,
  text: string,
  value: string,
}
export type typeProduct = {
  id: number,
  idCategory: number,
  idSubcategory: number,
  visible: boolean,
  deleted: boolean,
  idManufacturer: number,
  type: string,
  name: string,
  image: string,
  pdf: string,
  price: string,
  availability: string,
  features: typeFeature[]
}
export default defineComponent({
  name: 'MainMenuMixin',
  mixins: [MainMixin],
  data () {
    return {
      topMenuItems: [
        {
          id: 0,
          visible: true,
          path: '/catalog',
          text: 'Каталог',
          image: '',
          items: [
            {
              id: 0,
              visible: true,
              text: 'Башенные краны',
              image: 'catalog-cranes.jpg',
              items: [{ id: 0, visible: true, text: 'Оголовочные', image: 'sub-catalog-cranes-ogolov.jpg' }, { id: 1, visible: true, text: 'Безголовочные', image: 'sub-catalog-cranes-bezgolov.jpg' }, { id: 2, visible: true, text: 'Строительные подъемники', image: 'sub-catalog-cranes-podjemnik.jpg' }],
              filters: [
                { id: 0, visible: true, text: 'Производитель', values: [{ id: 0, visible: true, checked: false, text: 'MG', products: [0, 1, 2] }], subcategories: [0, 1] }
              ],
              desc: 'Поставляемые нами башенные краны отличает простота монтажа, удобство управления, надежность и долговечность. Различные конфигурации и варианты исполнения для любых задач и условий эксплуатации.'
            },
            // { id: 1, visible: true, text: 'Бетонотехника', image: 'catalog-concrete.jpg', items: [{ id: 0, visible: true, text: 'Автобетоносмесители', image: 'sub-catalog-concrete-betonovoz.jpg' }, { id: 1, visible: true, text: 'Бетонораздаточные стрелы', image: 'sub-catalog-concrete-strela.jpg' }, { id: 2, visible: true, text: 'Линейные насосы', image: 'sub-catalog-concrete-lin-nasos.jpg' }, { id: 4, visible: true, text: 'Бетононасосы', image: 'sub-catalog-concrete-nasos.jpg' }, { id: 5, visible: true, text: 'Автобетононасосы со стрелой', image: 'sub-catalog-concrete-autostrela.jpg' }], filters: [], desc: 'Специальные транспортные средства, предназначенные для транспортировки и смешивания бетона. Надежная техника, которая позволяет доставлять бетон на большие высоты и в любые труднодоступные места на стройплощадке.' },
            // { id: 2, visible: true, text: 'Погрузчики', image: 'catalog-loaders.jpg', items: [{ id: 0, visible: true, text: 'Фронтальные', image: 'sub-catalog-loaders-front.jpg' }, { id: 1, visible: true, text: 'Вилочные', image: 'sub-catalog-loaders-fork.jpg' }], filters: [], desc: 'Незаменимая, чрезвычайно опциональная спецтехника с действительно широкой областью применения. Объем ковша погрузчиков достигает 7м³, грузоподъемность - до 7т.' },
            { id: 3, visible: true, text: 'Экскаваторы', image: 'catalog-excavators.jpg', items: [{ id: 0, visible: true, text: 'Небольшие', image: 'sub-catalog-excav-small.jpg' }, { id: 1, visible: true, text: 'Средние', image: 'sub-catalog-excav-middle.jpg' }, { id: 2, visible: true, text: 'Большие', image: 'sub-catalog-excav-big.jpg' }, { id: 3, visible: true, text: 'Long Reach', image: 'sub-catalog-excav-lr.jpg' }], filters: [], desc: 'Гусеничные экскаваторы известного китайского бренда LONKING способны легко справляться с задачами различного типа и сложности. Опционально могут быть снабжены скальными, угольными ковшами, ковшами увеличенного объема, стрелами типа «Long Reach» и гидромолотами.' }
          ]
        },
        // { id: 1, visible: true, path: '/service', image: '', text: 'Сервис', items: [] },
        // { id: 2, visible: true, path: '/lising', image: '', text: 'Лизинг', items: [] },
        // { id: 3, visible: true, path: '/about', image: '', text: 'О нас', items: [] },
        { id: 4, visible: true, path: '/contacts', image: '', text: 'Контакты', items: [] }
      ],
      products: [
        {
          id: 0,
          idCategory: 0,
          idSubcategory: 0,
          visible: true,
          deleted: false,
          idManufacturer: 0,
          type: 'Тип продукции',
          name: 'Название модели',
          image: '',
          pdf: 'Файл с брошюрой',
          price: '1000',
          availability: '',
          features: [{ id: 0, visible: true, type: 'value', text: 'Характеристика 1', value: 'Значение 1' }, { id: 1, visible: true, type: 'title', text: 'Характеристика 2', value: 'Значение 2' }]
        }
      ],
      productsOriginal: [
        {
          id: 0,
          idCategory: 0,
          idSubcategory: 0,
          visible: true,
          deleted: false,
          idManufacturer: 0,
          type: 'Тип продукции',
          name: 'Название модели',
          image: '',
          pdf: 'Файл с брошюрой',
          price: '1000',
          availability: '',
          features: [{ id: 0, visible: true, type: 'value', text: 'Характеристика 1', value: 'Значение 1' }, { id: 1, visible: true, type: 'title', text: 'Характеристика 2', value: 'Значение 2' }]
        }
      ],
      manufacturers: [
        { id: 0, text: 'MG', image: 'mg.svg', desc: '' },
        { id: 1, text: 'Dahan', image: 'dahan.svg', desc: '' },
        { id: 2, text: 'Lonking', image: 'lonking.svg', desc: '' },
        { id: 3, text: 'Sunshine', image: 'sunshine.svg', desc: '' }
      ]
    }
  },
  created () {
    this.fileName = '/data/main-menu.d'
    this.topMenuItems = []
    this.products = []
    this.productsOriginal = []
    // this.loadJSON(() => {
    //   this.topMenuItems = this.content
    // })
  }

})

</script>
