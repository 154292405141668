<template>
  <nav v-if="loaded">
    <template v-for="menuItem in topMenuItems">
      <div
        v-if="menuItem.visible"
        :key="menuItem.id"
      >
        <!-- Пункты меню без подменю -->
        <router-link
          v-if="!menuItem.items.length"
          :to="menuItem.path"
        >
          <div class="main_menu_item">
            {{ menuItem.text }}
          </div>
        </router-link>
        <!-- Пункты меню с подменю -->
        <button
          v-else
          :class="['main_menu_item', { white_bg : submenuVisible && (menuItem.id === curentId) }]"
          @keyup.enter="submenuVisible = !submenuVisible; curentId = menuItem.id"
          @touchstart="submenuVisible = !submenuVisible; curentId = menuItem.id"
          @mouseenter="submenuVisible = true; curentId = menuItem.id"
          @mouseleave="submenuVisible = false; curentId = menuItem.id"
        >
          {{ menuItem.text }}
          <div class="submenu_wrapper">
            <sub-menu
              :item-id="menuItem.id"
              :is-visible="submenuVisible && (menuItem.id === curentId)"
            />
          </div>
        </button>
      </div>
    </template>
  </nav>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import MainMenuMixin from '@/components/MainMenuMixin.vue'
import SubMenu from '@/components/SubMenu.vue'
export default defineComponent({
  name: 'MainMemu',
  components: {
    SubMenu
  },
  mixins: [MainMenuMixin],
  data () {
    return {
      curentId: 0, // id активного пункта меню, в котором есть подменю
      submenuVisible: false
    }
  },
  watch: {
    '$route.path' () {
      this.submenuVisible = false // Убираем подменю при переходе по ссылке
    }
  },
  mounted () {
    this.loadJSON(() => {
      this.topMenuItems = this.content
    })
  },
  methods: {
    setSubmenuVisible (subMenu : boolean) {
      if (subMenu) { this.submenuVisible = true }
    },
    setSubmenuUnvisible () {
      setTimeout(() => { this.submenuVisible = false }, 200)
    }
  }
})
</script>

<style scoped>

nav {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(5rem, 1fr));
  justify-items: center;
  font-size: .9rem;
  width: 100%;
  max-width: 30rem;
}

nav a.router-link-exact-active {
  font-weight: bold;
}

.main_menu_item {
  display: flex;
  justify-content: center;
  align-items: center;
  white-space: nowrap;
  height: 100%;
}

.submenu_wrapper {
  position: absolute;
  width: 100%;
  left: 0;
  top: var(--header_height);
  overflow: hidden;
}

.white_bg {
  /* background-color: rgba(255, 255, 255, 0.95); */
  font-weight: 300;
  /* padding: 0 20rem; */
}

</style>
