import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, normalizeClass as _normalizeClass, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = (n: any) => (_pushScopeId("data-v-30c125e7"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "dots_control_container" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.numberOfItems, (n, indx) => {
      return (_openBlock(), _createElementBlock("div", {
        key: n,
        class: _normalizeClass(['control_dot', { control_dot_active: indx === _ctx.activeIndex }])
      }, null, 2))
    }), 128))
  ]))
}