<template v-if="loaded">
    <img
      v-if="!showMenu"
      src="/img/icon_dots.svg"
      alt=""
      class="icon_image"
      @click="setActive"
    >
    <transition name="burger_menu">
      <nav v-if="loaded && showMenu">
        <div
          class="left_arrow"
          @click="setActive"
        />
        <div
          v-for="menuItem in topMenuItems"
          :key="menuItem.id"
          :class="['cms_menu_item', {_hidden: !menuItem.visible}]"
        >
          <router-link
            v-if="menuItem.items.length === 0"
            :to="'/cms' + menuItem.path"
          >
            <div :class="[{_hidden: !menuItem.visible}]">
              {{ menuItem.text }}
            </div>
          </router-link>
          <div
            v-else
            :class="['cms_menu_item']"
          >
            <router-link :to="'/cms' + menuItem.path">
              <div class="cms_menu_item">
                {{ menuItem.text }}
              </div>
            </router-link>
            <div class="cms_submenu">
              <div
                v-for="submenuItem in menuItem.items"
                :key="submenuItem.id"
                :class="['cms_submenu_item', {_hidden: !submenuItem.visible}]"
              >
                <router-link :to="'/cms/product/' + submenuItem.id">
                  {{ submenuItem.text }}
                </router-link>
                <div class="cms_submenu_submenu">
                  <div
                    v-for="submenuSubmenuItem in submenuItem.items"
                    :key="submenuSubmenuItem.id"
                    :class="['cms_submenu_submenu_item', {_hidden: !submenuSubmenuItem.visible}]"
                  >
                    <router-link :to="'/cms/product/' + submenuItem.id + '/' + submenuSubmenuItem.id">
                      {{ submenuSubmenuItem.text }}
                    </router-link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <router-link to="/cms/footer">
          <div class="cms_menu_item">
            Текст внизу страницы
          </div>
        </router-link>
      </nav>
    </transition>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import MainMenuMixin from '@/components/MainMenuMixin.vue'
export default defineComponent({
  name: 'CmsLeftMenuBurger',
  mixins: [MainMenuMixin],
  emits: ['showBurgerMenu', 'hideBurgerMenu'],
  data () {
    return {
      curentId: 0, // id активного пункта меню, в котором есть подменю
      homePath: '/',
      showMenu: false
    }
  },
  watch: {
    '$route.path' () {
      this.showMenu = false // Убираем меню при переходе по ссылке
      this.$emit('hideBurgerMenu') // Генерируем событие для восстановления прокрутки страниц
    }
  },
  mounted () {
    this.loadJSON(() => {
      this.topMenuItems = this.content
    })
  },
  methods: {
    setActive () {
      this.showMenu = !this.showMenu
      if (this.showMenu) {
        console.log('showBurgerMenu')
        this.$emit('showBurgerMenu')
      } else {
        console.log('hideBurgerMenu')
        this.$emit('hideBurgerMenu') // Генерируем событие для восстановления прокрутки страниц
      }
    }
  }
})
</script>

<style scoped>

.icon_image {
  position: fixed;
  width: 3rem;
  align-self: center;
  left: 0;
  top: 1.5rem;
  cursor: pointer;
  z-index: 1;
}

.left_arrow {
  position: fixed;
  top: 2rem;
  left: 2rem;
  width: 1.3rem;
  height: 1.3rem;
  border-left: 4px solid #000;
  border-bottom: 4px solid #000;
  transform: rotate(45deg);
}

nav {
  position: absolute;
  top: 0;
  font-size: 1.3rem;
  width: 100%;
  min-height: 100%;
  padding: 5rem 3rem 3rem 3rem;
  /* background: linear-gradient(70deg, #005c97, #3895b4); */
  /* background: linear-gradient(70deg, #003758, #3895b4); */
  background-color: #f3f3f3;
  /* opacity: .95; */
  /* box-shadow: 10px 0 50px -7px rgba(34,60,80,.3); */
  box-shadow: 30px -50px 100px -7px rgba(34,60,80,.1) inset;
  color: #eee;
  z-index: 1001;
  line-height: 2rem;
}

nav a {
  font-size: 1.3rem;
  font-weight: 400;
}

nav a.router-link-exact-active {
  font-weight: bolder;
  position: relative;
}
/*
nav a.router-link-exact-active::before {
  display: block;
  position: absolute;
  top: 1.1rem;
  left: -1.5rem;
  height: 0.6rem;
  width: 0.6rem;
  content: "";
  border-radius: 0.5rem;
  background-color: #009bc2;
} */

  .cms_menu_item {
    font-weight: 600;
    padding: .5rem 0;
    border-bottom: .5px solid #c6c6c6;
}

  .cms_menu_item:last-child {
    border-bottom: none;
  }

  .cms_submenu {
    margin: 1rem 0 0 1rem;
  }

  .cms_submenu_item {
    padding: 1rem 0 0 0;
    font-size: .9rem;
    border-bottom: .5px solid #c6c6c6;
  }

.cms_submenu_item:last-child {
  border-bottom: none;
}
  .cms_submenu_item a {
    font-weight: 500;
  }

  .cms_submenu_submenu {
    padding: 0.5rem  0 1rem 1rem;
  }

  .cms_submenu_submenu_item {
    padding: .2rem 0;
    font-size: .9rem;
  }

  .cms_submenu_submenu_item a {
    font-weight: 300;
  }

</style>
