<template>
  <button>
    <div
      v-if="image"
      class="button_container"
      :style="{width: width}"
    >
      <div
        v-if="image"
        :style="{backgroundColor: iconColor}"
      >
        <img
          :src="image"
          alt=""
        >
      </div>
      <div>{{ text }}</div>
    </div>
    <div
      v-else
      class="button_container_no_image"
      :style="{width: width}"
    >
      <div>{{ text }}</div>
    </div>
  </button>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
export default defineComponent({
  name: 'CmsButton',
  props: {
    image: {
      type: String,
      default: ''
    },
    text: {
      type: String,
      default: ''
    },
    width: {
      type: String || undefined
    },
    iconColor: {
      type: String || undefined
    }
  }
})
</script>

<style scoped>
button {
  cursor: pointer;
  /* width: 10rem; */
  width: fit-content;
  background: linear-gradient(to bottom, #4dc7d9 0%, #5FB0CC 100%);
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 500;
  border: none;
  border-radius: .3rem;
  overflow: hidden;
  box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.2);
  transition: all 0.1s;
}

button:hover {
  transform: translateY(-.5px);
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.2);
}

button:active {
  transform: scale(0.99);
  box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.2);
}

.button_container {
  display: grid;
  grid-template-columns: 2rem auto;
  height: 2.5rem;
}

.button_container>div:nth-of-type(1) {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 2.5rem;
  border-right: 1px solid rgba(255, 255, 255, 0.414);
}

.button_container>div:nth-of-type(1) img {
  height: 1.5rem;
}

.button_container>div:nth-of-type(2) {
  padding: 0.8rem 1.2rem;
}

.button_container_no_image {
  display: grid;
}

.button_container_no_image>div:nth-of-type(1) {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 1rem;
  height: 2.5rem;
  border-right: 1px solid rgba(255, 255, 255, 0.414);
}

</style>
