<template>
  <div class="dots_control_container">
    <div
      v-for="(n, indx) in numberOfItems"
      :key="n"
      :class="['control_dot', { control_dot_active: indx === activeIndex }]"
    />
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
export default defineComponent({
  name: 'DotsNavigation',
  props: {
    numberOfItems: {
      type: Number,
      default: 0
    },
    activeIndex: {
      type: Number,
      default: 0
    }
  },
  data () {
    return {
    }
  }
})
</script>

<style scoped>

.dots_control_container {
  display: flex;
  height: 1rem;
  flex-direction: row;
  gap: 1rem;
  justify-content: center;
  align-items: center;
  padding: 1rem;
}

.control_dot {
  width: .3rem;
  height: .3rem;
  border-radius: .15rem;
  background-color: #a2a2a2;
  transition: .5s cubic-bezier(0.075, 0.82, 0.165, 1);
}

.control_dot_active {
  width: .5rem;
  height: .5rem;
  border-radius: .25rem;
  background-color: #333333;
  transition: .5s cubic-bezier(0.075, 0.82, 0.165, 1);
}

</style>
