<template>
  <transition :name="isMobile ? 'bottom-top' : 'left-right'">
    <div :class="['user_control', {user_control_mobile: isMobile}]">
      <div
        v-if="!isMobile"
        class="right_menu_user"
      >
        <img src="/img/icon_user.svg">
        {{ user }}
      </div>
      <a href="/cms/logout">
        <img src="/img/icon_logout.svg">
        <span v-show="!isMobile">Выйти</span>
      </a>
      <router-link
        :v-if="!waitForConfirm"
        to="/cms/recycle"
      >
        <img src="/img/icon_recycle.svg">
        <span v-show="!isMobile">Корзина</span>
      </router-link>
      <router-link to="/cms/messages">
        <img src="/img/icon_mail.svg">
        <span v-show="!isMobile">Сообщения</span>
      </router-link>
      <router-link
        :v-if="!waitForConfirm"
        to="/cms/settings"
      >
        <img src="/img/icon_settings.svg">
        <span v-show="!isMobile">Настройки сайта</span>
      </router-link>
      <router-link
        :v-if="!waitForConfirm"
        to="/cms/backup"
      >
        <img src="/img/icon_backup.svg">
        <span v-show="!isMobile">Резервные копии</span>
      </router-link>
      <router-link to="/cms/users">
        <img src="/img/icon_users.svg">
        <span v-show="!isMobile">Пользователи</span>
      </router-link>
    </div>
  </transition>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
export default defineComponent({
  name: 'CmsRightMenu',
  props: {
    user: {
      type: String,
      default: ''
    },
    isMobile: {
      type: Boolean,
      default: false
    },
    waitForConfirm: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      isImageManager: false
    }
  },
  methods: {
    runImageManager () {
      document.body.classList.add('_scroll_stop')
      this.isImageManager = true
    }
  }
})
</script>

<style scoped>

.right_menu_user {
  display: grid;
  justify-items: center;
}

.user_control_container {
    position: relative;
}

.user_control {
    position: fixed;
    right: 0;
    top: 0;
    width: 6rem;
    height: 100%;
    font-size: .7rem;
    /* background-color: #f0f0f0; */
    /* background-color: #85bbab25; */
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    padding: 2rem 0 .5rem 0;
    z-index: 1;
    /* transition: 700ms cubic-bezier(0.075, 0.82, 0.165, 1); */
}

.user_control a {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 2rem 1rem 0;
    padding: .5rem;
    width: 100%;
    transition: 700ms cubic-bezier(0.075, 0.82, 0.165, 1);
}

@media (hover: hover) and (pointer: fine) {
    .user_control a:hover {
        background-color: #b9c5c11e;
    }
}

.user_control img {
    height: 1.5rem;
    margin-bottom: .5rem;
}

/* =============== */
/*     Mobile      */
/* =============== */

.user_control_mobile {
  bottom: 0;
  top: auto;
  width: 100%;
  height: 6rem;
  flex-direction: row;
  justify-content: space-around;
  padding: 1rem;
  z-index: 1000;
  background: #fafafa;
  box-shadow: 0 0 20px 0 rgba(34, 60, 80, 0.1);
}

.user_control_mobile a {
  margin: 0;
  padding: 0;
  justify-content: center;
  height: 6rem;
}

</style>
