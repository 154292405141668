<template>
  <div class="wrapper">
    <main-menu-burger
      v-if="isMobile"
      @show-burger-menu="$emit('showBurgerMenu')"
      @hide-burger-menu="$emit('hideBurgerMenu')"
    />
    <div :class="['header', { header_mobile: isMobile }]">
      <main-logo :is-mobile="isMobile" />
      <div
        v-if="!isMobile"
        class="flex_center"
      >
        <main-menu />
      </div>
      <a v-if="!isMobile" href="/order" :class="['order', { order_mobile: isMobile }]">
        <img src="/img/icon_order.svg" alt="" class="order_image">
        <span v-if="!isMobile">Заявка</span>
        <span v-if="numberOfOrders" class="order_counter">{{ numberOfOrders }}</span>
      </a>
      <location-menu />
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent  } from 'vue'
import MainMenu from '@/components/MainMenu.vue'
import MainMenuBurger from '@/components/MainMenuBurger.vue'
import LocationMenu from '@/components/LocationMenu.vue'
import MainLogo from '@/components/MainLogo.vue'
export default defineComponent({
  name: 'MainHeader',
  components: {
    MainMenu, MainMenuBurger, LocationMenu, MainLogo
  },
  props: {
    isMobile: {
      type: Boolean,
      default: false
    },
    numberOfOrders: {
      type: Number,
      default: 0
    }
  },
  emits: ['showBurgerMenu', 'hideBurgerMenu'],
})
</script>

<style scoped>

.wrapper {
  display: flex;
  justify-content: center;
  width: 100%;
  z-index: 1000
}
.header {
  position: relative;
  width: 100%;
  max-width: var(--max_width);
  height: var(--header_height);
  display: grid;
  grid-template-columns: auto 1fr 6rem 10rem;
  background-color: var(--background-color);
  padding: 0 3rem;
}

.header_mobile {
  position: fixed;
  grid-template-columns: 1fr;
  background-color: var(--background_color);
  box-shadow: 0 0 50px -7px rgba(34,60,80,.3);
  padding: 0;
}

.flex_center {
  display: flex;
  justify-content: center;
}

.order {
  position: relative;
  display: flex;
  cursor: pointer;
  flex-direction: column;
  align-items: center;
  padding: 1rem;
}

.order_counter {
  position: absolute;
  top: 1rem;
  right: 1rem;
  font-size: .9rem;
  font-weight: bold;
  color: red;
}

.order_mobile {
  position: absolute;
  top: 1rem;
  right: 0rem;
  width: 4rem;
  height: 100%;
  background-color: transparent;
}

.order_image {
  width: 2rem;
  height: 2rem;
}

</style>
