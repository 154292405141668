<template>
    <div
      key="id1"
      class="window_backdrop"
    />
    <div :class="['window_container', {window_container_mobile: showMobile}]">
      <div :class="['image_viewer_container', {gray_rectangle: error}]">
        <transition name="fade">
          <img
            v-if="!error"
            :src="file"
            :class="[{_full_size : fullSize}, 'image_viewer_image', {_display_none : !visible}, {_hidden : !loaded}, {_visible : loaded}]"
            @load="setLoaded"
            @error="setError"
            @click="fullSize = !fullSize"
          >
        </transition>
        <cms-loader
          v-if="!loaded && visible"
          class="_loader"
        />
      </div>
      <shadow-div class="shadow" />
      <div
        v-if="numberOfImages > 1"
        class="_left"
        @click="$emit('imageViewerPrev')"
      >
        <span class="arrow_left" />
      </div>
      <div
        v-if="numberOfImages > 1"
        class="_right"
        @click="$emit('imageViewerNext')"
      >
        <span class="arrow_right" />
      </div>
      <img
        src="/img/icon_plus.svg"
        class="image_control"
        @click="$emit('imageViewerClose')"
      >
      <dots-navigation
        v-if="numberOfImages > 1"
        :number-of-items="numberOfImages"
        :active-index="activeIndex"
        class="dots_navigation"
      />
    </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import CmsLoader from '@/components/CmsLoader.vue'
import DotsNavigation from '@/components/DotsNavigation.vue'
export default defineComponent({
  name: 'ImageViewer',
  components: {
    CmsLoader, DotsNavigation
  },
  props: {
    file: {
      type: String,
      default: ''
    },
    numberOfImages: {
      type: Number,
      default: 0
    },
    activeIndex: {
      type: Number,
      default: 0
    },
    visible: { // display:none для предзагрузки img
      type: Boolean,
      default: true
    },
    showMobile: {
      type: Boolean,
      default: false
    }
  },
  emits: {
    imageViewerLoaded: () => { return true },
    imageViewerPrev: () => { return true },
    imageViewerNext: () => { return true },
    imageViewerError: () => { return true },
    imageViewerClose: () => { return true }
  },
  data () {
    return {
      fullSize: false,
      loaded: false,
      error: false
    }
  },
  mounted () {
    document.body.addEventListener('keyup', e => {
      switch (e.code) {
        case 'Escape':
          this.$emit('imageViewerClose')
          break
        case 'ArrowLeft':
          this.$emit('imageViewerPrev')
          break
        case 'ArrowRight':
          this.$emit('imageViewerNext')
          break
      }
    })
  },
  methods: {
    setLoaded () {
      this.loaded = true
      this.$emit('imageViewerLoaded')
    },
    setError () {
      this.loaded = true
      this.error = true
      this.$emit('imageViewerError')
    }
  }
})
</script>

<style scoped>

.shadow{
  pointer-events: none;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  box-shadow: 0px 20px 30px -7px rgba(34, 60, 80, 0.1) inset;
  z-index: 3000;
}

.window_backdrop {
  position: fixed;
  margin: auto;
  width: 100%;
  height: 100%;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background-color: #00000032;
  /* backdrop-filter: blur(15px);
  -webkit-backdrop-filter: blur(15px); */
  z-index: 3000;
}

.window_container {
  position: fixed;
  margin: auto;
  min-width: 40rem;
  max-width: 60rem;
  height: 38rem;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  font-size: .8rem;
  background-color: #f7f7f7;
  border-radius: .5rem;
  border: 1px solid #b5b5b5;
  overflow: hidden;
  z-index: 1001;
  box-shadow: 0 0 1em #0f322a43;
  -webkit-box-shadow: 0 0 1em #0f322a43;
  z-index: 3000;
}

.window_container_mobile {
  min-width: unset;
  max-width: unset;
  height: unset;
}

.image_control {
  cursor: pointer;
  position: absolute;
  top: 2rem;
  right: 2rem;
  width: 3rem;
  height: 3rem;
  z-index: 3000;
  transform: rotate(45deg);
  transition: .8s cubic-bezier(0.075, 0.82, 0.165, 1);
  z-index: 3000;
  opacity: .3;
}

.image_control:hover {
  transform: scale(1.05) rotate(135deg);
  transition: .8s cubic-bezier(0.075, 0.82, 0.165, 1);
  opacity: 1;
}

.image_control:active {
  transform: scale(.5) rotate(135deg);
  transition: .8s cubic-bezier(0.075, 0.82, 0.165, 1);
  opacity: .3;
}

.image_viewer_container {
  position: relative;
  display: grid;
  align-items: center;
  justify-items: center;
  height: 100%;
  width: 100%;
  overflow: auto;
  background-color: #fff;
  /* box-shadow: 0px -20px 30px -7px rgba(34, 60, 80, 0.1) inset; */
  z-index: 3000;
}

._loader {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%,-50%);
}

.image_viewer_image {
  position: absolute;
  max-width: 60%;
  max-height: 100%;
  cursor: zoom-in;
}

._full_size {
  cursor: zoom-out;
  max-width: unset;
  max-height: unset;
  position: unset;
}

.gray_rectangle {
  background-color: #e3e3e3;
}

._hidden {
  opacity: 0;
  transform: scale(.97);
  transition: .5s ease-out;
}

._visible {
  opacity: 1;
  transform: scale(1);
  transition: .5s ease-out;
}

._display_none {
  display: none;
}

._left, ._right {
  cursor: pointer;
  position: absolute;
  display: grid;
  align-items: center;
  justify-items: center;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
  width: 5rem;
  height: 5rem;
  background-color: #b5b5b5;
  opacity: .3;
  z-index: 3001;
  transition: 1s cubic-bezier(0.075, 0.82, 0.165, 1);
}

._right {
  left: unset;
  right: 0;
}

._left:hover, ._right:hover {
  opacity: .7;
}

._left:active {
  transform: translate(-1rem, -50%);
  transition: 1s cubic-bezier(0.075, 0.82, 0.165, 1);
}

._right:active {
  transform: translate(1rem, -50%);
  transition: 1s cubic-bezier(0.075, 0.82, 0.165, 1);
}

.arrow_left, .arrow_right {
  display: block;
  border-left: 3px solid #eee;
  border-top: 3px solid #eee;
  width: 2rem;
  height: 2rem;
  transform: rotate(-45deg) translate(0.3rem, 0.3rem);
}

.arrow_right {
  transform: rotate(135deg) translate(0.3rem, 0.3rem);
}

.dots_navigation {
  position: absolute;
  bottom: 1rem;
  left: 50%;
  transform: translateX(-50%);
  z-index: 3003;
}

</style>
